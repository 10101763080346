import { hideModal } from '../../PhillipsModal/actions';
import UserService from '../../../services/UserService';
import sendAnalytics from '../../../utils/sendAnalytics';

const userService = new UserService();

const logOutSuccess = () => ({
  type: 'USER_LOGOUT',
  payload: {
    user: {
      firstName: '',
      lastName: '',
      email: '',
      id: ''
    },
    favoriteLots: [],
    followedMakers: [],
    lotLists: [],
    saleRegistrations: []
  }
});
const logOutPending = () => ({
  type: 'USER_LOGOUT_PENDING'
});

// TODO: this is not ideal. We should work out how to remove this and make it part of the auth library integration
// it is confusing that login is from auth, but logout needs to be dispatched as an action
const logout = () => dispatch => {
  dispatch(logOutPending());
  userService.logout();
};

const setUser = ({ id, userName, firstName, lastName, email }) => ({
  type: 'USER_LOGIN',
  payload: {
    user: {
      id,
      email,
      userName,
      firstName,
      lastName
    }
  }
});

const userFetched = user => ({
  type: 'USER_FETCHED',
  payload: {
    favoriteLots: user.favoriteLots,
    followedMakers: user.followedMakers,
    lotLists: user.lotLists,
    saleRegistrations: user.saleRegistrations,
    title: user.title
  }
});

const loggedIn = user => dispatch => {
  dispatch(setUser(user));
  userService.fetchUserDetails().then(userModel => {
    console.log('userModal:', userModel);
    dispatch(userFetched(userModel));
  });
};

const loginUserError = payload => ({
  type: 'USER_LOGIN_ERROR',
  payload
});

const editUserPending = formName => ({
  type: 'EDIT_USER_PENDING',
  payload: { formName }
});

const editUserSuccess = payload => ({
  type: 'EDIT_USER_SUCCESS',
  payload
});

const editUserError = payload => ({
  type: 'EDIT_USER_ERROR',
  payload
});

const editUser = (payload, userId, formName) => dispatch => {
  dispatch(editUserPending(formName));
  userService
    .editUser({ payload, formName, userId })
    .then(res => dispatch(editUserSuccess({ ...res, ...payload, formName })))
    .catch(err => dispatch(editUserError({ message: err.message, formName })));
};

const editAddressPending = formName => ({
  type: 'EDIT_ADDRESS_PENDING',
  payload: { formName }
});

const editAddressSuccess = payload => ({
  type: 'EDIT_ADDRESS_SUCCESS',
  payload
});

const editAddressError = payload => ({
  type: 'EDIT_ADDRESS_ERROR',
  payload
});

const editAddress = (payload, userId, formName) => dispatch => {
  dispatch(editAddressPending(formName));
  userService
    .editAddress({ payload, userId })
    .then(res => dispatch(editAddressSuccess({ ...res, ...payload, formName })))
    .catch(err => dispatch(editAddressError({ message: err.message })));
};

export { loggedIn, logOutSuccess, loginUserError, setUser, userFetched, editUser, logout, editAddress };
