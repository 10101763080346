/* eslint-disable no-confusing-arrow */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNull from 'lodash/isNull';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Cookies from 'cookies-js';
import { AuthState } from '@phillipsauctionhouse/auth-js/types';
import { stickyPropType } from '../PropTypes/proptypes';
import Header from './Header';
import NotificationBanner from './NotificationBanner';
import OffCanvas from './OffCanvas';
import PhillipsSearch from './PhillipsSearch';
import { showModal } from '../PhillipsModal/actions';
import { logout } from '../PhillipsUser/actions';
import bindUserModel from '../PhillipsUser/bindUserModel';
import getPhillipsBackboneProperty from '../../utils/getPhillipsBackboneProperty';
import { getCurrentLanguage, setCurrentLanguage } from '../../utils/languageManagement';
import getI18nLabel, { setI18nLinkLanguage } from '../../utils/getI18nLabel';
import UserService from '../../services/UserService';
import base64 from '../../utils/base64EncodeDecode';
import StickyBanner from './StickyBanner';
import sendAnalytics from '../../utils/sendAnalytics';
import { useAuth } from '../Auth';

const validateBanner = banner => (isNull(banner) ? banner : JSON.parse(base64.decode(banner)));
const userService = new UserService();

const Layout = props => {
  const { authState, login } = useAuth();
  const banner = validateBanner(props.notificationBanner);
  const [showBanner, toggleBanner] = useState(isNull(banner) ? false : !banner.viewed);
  const [type, handleOffCanvas] = useState('');
  const [hasSticky, setHasSticky] = useState(props.sticky && props.sticky.length > 0);
  const phillipsEvents = useRef(null);
  const phillipsUser = useRef(null);
  const dispatch = useDispatch();
  const { modal, user, language, headerAuctions, location, privateSalesMenu } = useSelector(state => state);
  const amBidsUrl = window?.location?.host === "www.qa.phillips.com" ? "https://live.qa.phillips.com/bid-sheet/upcoming" : "https://live.phillips.com/bid-sheet/upcoming";
  const changeLanguage = selectedLanguage => {
    const lang = selectedLanguage === 'en' ? 'en' : selectedLanguage === 'zh' ? 'zh' : 'en'
    const query = location.query || {};
    const actionType = lang === 'en' ? 'ROUTES_DEFAULT' : 'ROUTES_LANGUAGE';
    const action = {
      type: actionType,
      payload: {
        language: lang
      },
      query
    };
    dispatch(action);
    // global update
    setCurrentLanguage(lang);
    phillipsEvents.current.trigger('update', action);
  };
  React.useEffect(() => {
    if (authState === AuthState.LoggedIn && user.loggedIn !== true) {
      userService
        .fetchUser()
        .then(res => {
          if (typeof phillips.user !== 'undefined') {
            phillips.user.trigger('loggedIn', res);
          }
        })
        .catch(err => {
          console.log('err: ', err);
        });
    }
  }, [authState, dispatch, user])

  React.useEffect(() => {  
    if(sessionStorage.getItem('perpetualSaleNumber') === null || sessionStorage.getItem('perpetualSaleNumber') === '') {
      userService
        .getPerpetualConfig()
        .then(res => {
          sessionStorage.setItem('perpetualSaleNumber', res.perpetualSaleNumber);
        })
        .catch(err => {
          console.log('err: ', err);
        });
    }
      
    
  }, [])
  useEffect(() => {
    // set phillips.Events to ref
    getPhillipsBackboneProperty('Events')
      .then(res => {
        phillipsEvents.current = res;
        const cookieLanguage = getCurrentLanguage() || 'en';
        if (language !== cookieLanguage) {
          changeLanguage(language);
        }
      })
      .catch(err => console.error('There was an error fetching phillips.Events: ', err));
    getPhillipsBackboneProperty('user')
      .then(u => {
        bindUserModel(u, dispatch);
        phillipsUser.current = u;
        // window.postMessage({ phillipsLang: 'en' });
        phillipsUser.current.checkStorage();
      })
      .catch(err => console.log('Error fetching user model: ', err));
  }, [language, changeLanguage]);
  const handleLanguageChange = selectedLanguage => {
    if (language !== selectedLanguage) {
      changeLanguage(selectedLanguage);
      if (type.length > 0) {
        handleOffCanvas('');
      }
      if (window?.location.pathname.startsWith('/article/')) {
        const pathName = window.location.pathname;
        const flocklerId = pathName.split('/').filter(str => str.length > 0 && str !== 'article')[0];
        switch (flocklerId) {
          case '65563948':
            window.location.assign('/article/58561057/auction-bidding-anywhere');
            break;
          case '58561057':
            window.location.assign('/article/65563948/bids-anywhere-cn/');
            break;
          case '69138631':
            window.location.assign('/article/70020146/2021-office-gallery-closures-procedures-cn/');
            break;
          case '70020146':
            window.location.assign('/article/69138631/2021-office-gallery-closures-procedures');
            break;
          default:
            break;
        }
      }
    }
  };
  const handleLogout = () => dispatch(logout());
  const handleLogin = () => login();
  const openModal = e => {
    dispatch(showModal({ type: e.target.value }));
    if (type.length > 0) {
      handleOffCanvas('');
    }
  };
  const onClickOffCanvas = e => {
    const newType = e.currentTarget.value === type ? '' : e.currentTarget.value;
    handleOffCanvas(newType);
  };
  const getLabel = getI18nLabel(language);
  const closeBanner = () => {
    Cookies.set(`notification`, base64.encode(JSON.stringify({ ...banner, viewed: true })), {
      expires: 30,
      path: '/'
    });
    toggleBanner(false);
  };
  return (
    <Fragment>
      {showBanner ? (
        <NotificationBanner
          data={banner}
          handleClose={closeBanner}
          getI18nLabel={getLabel}
          getI18nLink={setI18nLinkLanguage(language)}
        />
      ) : null}
      {hasSticky ? (
        <StickyBanner
          language={language}
          data={props.sticky}
          getI18nLabel={getLabel}
          handleClose={() => setHasSticky(false)}
        />
      ) : null}
      <Header
        notificationEnabled={showBanner}
        activeNode={props.activeNode}
        getI18nLabel={getLabel}
        handleLanguageChange={handleLanguageChange}
        openModal={openModal}
        handleOffCanvas={onClickOffCanvas}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        language={language}
        user={user}
        headerAuctions={headerAuctions}
        privateSalesMenu={privateSalesMenu}
        hasSticky={hasSticky}
        amBidsUrl={amBidsUrl}
      />
      <OffCanvas
        activeNode={props.activeNode}
        getI18nLabel={getLabel}
        handleLanguageChange={handleLanguageChange}
        openModal={openModal}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        isOpen={type === 'side-menu'}
        language={language}
        user={user}
        headerAuctions={headerAuctions}
        privateSalesMenu={privateSalesMenu}
        amBidsUrl={amBidsUrl}
      />
      <PhillipsSearch language={language} isOpen={type === 'search'} />
      <button
        className={classNames('phillips__nav__overlay', {
          'phillips__nav__overlay--visible': type.length > 0
        })}
        type="button"
        name="exit-off-canvas"
        onClick={onClickOffCanvas}
        value=""
        title="Close"
      />
    </Fragment>
  );
};

Layout.defaultProps = {
  activeNode: null,
  sticky: [],
  notificationBanner: null
};
Layout.propTypes = {
  activeNode: PropTypes.string,
  sticky: PropTypes.arrayOf(PropTypes.shape(stickyPropType)),
  notificationBanner: PropTypes.string
};

export default Layout;
